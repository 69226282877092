<template>
  <v-dialog v-model="dialog" max-width="500px" origin="center center">
    <v-card>
      <v-card-title>
        <span>Filtrar Produtos</span>
        <v-progress-circular
          indeterminate
          v-if="loading"
          class="ma-2"
          color="secondary"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <div class="row col-xs">
          <div class="col-md">
            <v-text-field
              v-model="filter.name"
              placeholder="Pesquisar"
              outlined
              hide-details="auto"
              dense
              class="user-search"
            />
          </div>
          <div class="col-md">
            <v-select
              :items="selectStatus"
              v-model="filter.status"
              item-text="text"
              item-value="value"
              label="Status"
              dense
              outlined
              hide-details="auto"
            ></v-select>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <v-select
              v-model="filter.platform"
              label="Plataforma"
              :items="platform"
              dense
              filled
              outlined
              hide-details="auto"
            ></v-select>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <money-input
              ref="minValueRef"
              v-model="filter.valueMin"
              label="Valor Mínimo"
            ></money-input>
          </div>
          <div class="col-md">
            <money-input
              ref="maxValueRef"
              v-model="filter.valueMax"
              label="Valor Máximo"
              :rules="[rules.greaterThanMin]"
            ></money-input>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submitFilter">Aplicar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Filter from "@/util/Filters";

import moneyInput from "@/components/app/ui/moneyInput.vue";
import formMixin from '@/util/mixins/formMixin';

export default {
  components: {
    moneyInput,
  },

  mixins: [formMixin],

  data() {
    return {
      loading: false,
      dialog: false,

      platform: ["Braip", "Monetizze", "Eduzz", "Hotmart"],

      filter: {
        name: null,
        platform: null,
        valueMin: null,
        valueMax: null,
        status: null,
        is_filter: true,
      },

      selectStatus: [
        { text: "Ativo", value: 1 },
        { text: "Inativo", value: 0 },
      ],

      rules: {
        greaterThanMin: (v) =>
          this.filter.valueMin <= this.filter.valueMax ||
          "O valor máximo deve ser maior que o valor minimo.",
      },

      touched: false,
    };
  },

  methods: {
    open() {
      this.filter.name = null;
      this.filter.platform = null;
      this.filter.valueMin = null;
      this.filter.valueMax = null;
      this.filter.status = null;
      this.filter.is_filter = true;
      this.touched = false;
      this.dialog = true;
    },

    submitFilter() {
      if (this.touched) {
        if (this.filter.valueMin == 0) {
          this.filter.valueMin = null;
        }

        if (this.filter.valueMax == 0) {
          this.filter.valueMax = null;
        }

        this.$emit("filter", this.filter);
      }
      this.dialog = false;
    },
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        this.touched = true;
      },
    },
  },
};
</script>
<style>
.v-text-field__prefix {
  margin-top: 0px !important;
}
</style>
<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>
        {{ isUpdating ? "Editar" : "Cadastrar" }} Produto
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title class="d-flex align-center justify-space-between">
        <div>
          <v-switch
            v-model="productForm.has_integration"
            inset
            label="Habilitar Integração"
          ></v-switch>
        </div>
        <v-radio-group
          v-model="productForm.product_type"
          row
          :rules="[rules.required]"
        >
          <v-radio label="Produto" :value="1"></v-radio>
          <v-radio label="Serviço" :value="2"></v-radio>
        </v-radio-group>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2" />
      </div>
      <v-form v-show="!loading" v-model="formValid" ref="form">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-text-field
                v-model="productForm.name"
                :rules="[rules.required]"
                label="Nome"
                outlined
                dense
                hide-details="auto"
              />
            </div>
            <div class="col-md-3">
              <v-text-field
                v-model="productForm.sku"
                :rules="[rules.required]"
                label="Código"
                outlined
                dense
                hide-details="auto"
              />
            </div>
            <div class="col-md-3">
              <v-text-field
                v-model="productForm.bar_code"
                :rules="productForm.product_type == 2 ? [] : [rules.required]"
                :disabled="productForm.product_type == 2"
                label="Código de Barras"
                outlined
                dense
                hide-details="auto"
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-12">
              <v-textarea
                v-model="productForm.description"
                :rules="[rules.required]"
                label="Descrição"
                outlined
                dense
                hide-details="auto"
                auto-grow
              />
            </div>
          </div>
          <div class="row col-xs" v-if="dialog">
            <div class="col-md">
              <v-text-field
                v-model="productForm.unit"
                label="Unidade"
                outlined
                dense
                :rules="productForm.product_type == 2 ? [] : [rules.required]"
                :disabled="productForm.product_type == 2"
                hide-details="auto"
              />
            </div>
            <div class="col-md">
              <v-text-field
                v-model="productForm.weight"
                label="Peso"
                suffix="kg"
                outlined
                dense
                :rules="productForm.product_type == 2 ? [] : [rules.required]"
                :disabled="productForm.product_type == 2"
                hide-details="auto"
                type="number"
              />
            </div>
            <div class="col-md">
              <money-input
                ref="costInputRef"
                label="Valor de Compra"
                v-model="productForm.cost"
                :rules="[rules.required]"
              >
              </money-input>
            </div>
            <div class="col-md">
              <money-input
                ref="valueInputRef"
                label="Valor de Venda"
                v-model="productForm.value"
                :rules="[rules.required]"
              >
              </money-input>
            </div>
          </div>
        </v-card-text>
        <div v-if="productForm.product_type == 1" class="physical-product">
          <v-divider></v-divider>
          <v-card-title>
            <v-icon class="mr-1">
              {{ icons.mdiShopping }}
            </v-icon>
            <small>Dados Fiscais</small>
          </v-card-title>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-6">
                <v-select
                  v-model="productForm.outgoing_cfop"
                  :items="cfops"
                  label="CFOP"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
              <div class="col-md-3">
                <v-text-field
                  v-model="productForm.ncm"
                  label="NCM"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="number"
                  maxlength="8"
                />
              </div>
              <div class="col-md-3">
                <v-text-field
                  v-model="productForm.cest"
                  label="CEST"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="input"
                  maxlength="7"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-subtitle>
            <span class="font-weight-semibold text--primary me-1"> ICMS </span>
          </v-card-subtitle>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-4">
                <v-select
                  v-model="productForm.origin"
                  :items="origins"
                  label="Código origem"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
              <div class="col-md-4">
                <v-select
                  v-model="productForm.cst_icms"
                  :items="cstsIcms"
                  label="Cst ICMS"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
              <div class="col-md-4">
                <v-select
                  v-model="productForm.cst_icms_interstate"
                  :items="cstsIcms"
                  label="Cst ICMS interestadual"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-4">
                <v-text-field
                  v-model="productForm.icms_rate"
                  label="Aliquota ICMS"
                  suffix="%"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="number"
                />
              </div>
              <div class="col-md-4">
                <v-text-field
                  v-model="productForm.calculation_base_reduction"
                  label="Redução base de cálculo"
                  suffix="%"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="number"
                />
              </div>
              <div class="col-md-4">
                <v-text-field
                  v-model="productForm.icms_st_rate"
                  label="Aliquota ICMS ST"
                  suffix="%"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-subtitle>
            <span class="font-weight-semibold text--primary me-1"> IPI </span>
          </v-card-subtitle>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-8">
                <v-select
                  v-model="productForm.cst_ipi"
                  :items="cstsIpi"
                  label="Cst IPI"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
              <div class="col-md-4">
                <v-text-field
                  v-model="productForm.ipi_rate"
                  label="Aliquota IPI"
                  suffix="%"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-subtitle>
            <span class="font-weight-semibold text--primary me-1"> PIS </span>
          </v-card-subtitle>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-8">
                <v-select
                  v-model="productForm.cst_pis"
                  :items="cstsFederal"
                  label="Cst PIS"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
              <div class="col-md-4">
                <v-text-field
                  v-model="productForm.pis_rate"
                  label="Aliquota PIS"
                  suffix="%"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[rules.onlyNumbers, rules.required]"
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-subtitle>
            <span class="font-weight-semibold text--primary me-1">
              COFINS
            </span>
          </v-card-subtitle>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-8">
                <v-select
                  v-model="productForm.cst_cofins"
                  :items="cstsFederal"
                  label="Cst COFINS"
                  :rules="[rules.required]"
                  dense
                  outlined
                ></v-select>
              </div>
              <div class="col-md-4">
                <v-text-field
                  v-model="productForm.cofins_rate"
                  :rules="[rules.onlyNumbers, rules.required]"
                  label="Aliquota COFINS"
                  suffix="%"
                  outlined
                  dense
                  hide-details="auto"
                  type="number"
                  class="no-spinners"
                />
              </div>
            </div>
          </v-card-text>
        </div>
        <div v-if="productForm.product_type == 2">
          <v-divider></v-divider>
          <v-card-title>
            <v-icon class="mr-1">
              {{ icons.mdiShopping }}
            </v-icon>
            <small>Dados Fiscais</small>
          </v-card-title>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-12">
                <v-select
                  v-model="productForm.service_code"
                  :items="servicesCode"
                  label="Código de serviço"
                  dense
                  outlined
                  :rules="[rules.required]"
                ></v-select>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close">
          Cancelar
        </v-btn>
        <v-btn class="text-capitalize" color="success" @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentSave, mdiShopping, mdiClose } from "@mdi/js";
import {
  CST_ICMS,
  CST_PIS_COFINS,
  CST_IPI,
  CFOP_SAIDAS,
  ORIGIN_MERCHANDISE,
  SERVICE_CODE,
} from "@/constants/option";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import formMixin from "@/util/mixins/formMixin";

export default {
  name: "ProductForm",

  components: {
    moneyInput,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: null,
      isUpdating: false,
      loading: false,
      icons: {
        mdiShopping,
        mdiContentSave,
        mdiClose,
      },
      productForm: {
        id: null,
        product_type: null,
        name: null,
        bar_code: null,
        description: null,
        unit: null,
        weight: null,
        platform: null,
        calculation_base_reduction: null,
        icms_rate: null,
        icms_st_rate: null,
        product_platform_id: null,
        sku: null,
        link: null,
        cost: null,
        value: null,
        status: 1,
        has_integration: false,
        outgoing_cfop: null,
        outgoing_cfop_description: null,
        ncm: null,
        cest: null,
        origin: 0,
        cst_ipi: null,
        cst_icms: null,
        cst_ipi: null,
        cst_pis: null,
        pis_rate: null,
        ipi_rate: null,
        cofins_rate: null,
        cst_cofins: null,
        cst_icms_interstate: null,
        service_code: null,
      },
      cfops: CFOP_SAIDAS,
      cstsIcms: CST_ICMS,
      cstsFederal: CST_PIS_COFINS,
      cstsIpi: CST_IPI,
      cfopSelected: null,
      cstSelected: null,
      cstInterstateSelected: null,
      origins: ORIGIN_MERCHANDISE,
      servicesCode: SERVICE_CODE,
    };
  },
  methods: {
    open(id = null) {
      this.dialog = true;
      this.isUpdating = id != null;

      if (id) {
        this.getProduct(id);
      }
    },

    getProduct(id) {
      this.loading = true;
      this.$http.$get(`/product/${id}`, { params: { with: 'tax' }}).then((response) => {
        this.productForm = response.data;
        if (response.data.tax) {
          this.loadImposedData(response.data.tax)
        }
        this.$refs.valueInputRef.reset(response.data.value);
        this.$refs.costInputRef.reset(response.data.cost);
        this.loading = false;
      });
    },

    close() {
      this.productForm = {
        id: null,
        product_type: null,
        name: null,
        bar_code: null,
        description: null,
        unit: null,
        weight: null,
        platform: null,
        calculation_base_reduction: null,
        icms_rate: null,
        icms_st_rate: null,
        product_platform_id: null,
        sku: null,
        link: null,
        cost: null,
        value: null,
        status: 1,
        has_integration: false,
        outgoing_cfop: null,
        ncm: null,
        cest: null,
        origin: 0,
        cst_icms: null,
        cst_ipi: null,
        cst_pis: null,
        pis_rate: null,
        ipi_rate: null,
        cofins_rate: null,
        cst_cofins: null,
        cst_icms_interstate: null,
        service_code: null,
      };
      this.dialog = false;
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        if (this.isUpdating) {
          this.$http
            .$put(`/product/${this.productForm.id}`, this.productForm)
            .then((response) => {
              if (response.status == 200) {
                this.dialog = false;
                this.$emit("onSubmit");
              }
            });
        } else {
          this.$http.$post("/product", this.productForm).then((response) => {
            if (response.status == 200) {
              this.dialog = false;
              this.$emit("onSubmit");
            }
          });
        }
      }
    },
    loadImposedData(data) {
      this.productForm.origin = data.origin;
      this.productForm.cst_ipi = data.cst_ipi;
      this.productForm.cst_pis = data.cst_pis;
      this.productForm.pis_rate = data.pis_rate;
      this.productForm.cst_icms = data.cst_icms;
      this.productForm.ipi_rate = data.ipi_rate;
      this.productForm.icms_rate = data.icms_rate;
      this.productForm.cst_cofins = data.cst_cofins;
      this.productForm.cofins_rate = data.cofins_rate;
      this.productForm.icms_st_rate = data.icms_st_rate;
      this.productForm.outgoing_cfop = data.outgoing_cfop;
      this.productForm.cst_icms_interstate = data.cst_icms_interstate;
      this.productForm.outgoing_cfop_description = data.outgoing_cfop_description;
      this.productForm.calculation_base_reduction = data.calculation_base_reduction;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$refs.form.reset()
      }
    },
    'productForm.outgoing_cfop'(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        var text = this.cfops.find(item => item.value === newValue)?.text;
        this.productForm.outgoing_cfop_description = text.split(' - ')[1];
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
